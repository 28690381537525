import { Filter } from 'hooks/api/useApiGetArchivedInvoices'

type IconNames = 'Date' | 'Select' | 'SpreadsheetGeneric' | 'Flag' | 'CvcCvv'

interface IFormatAmountText {
  locale?: string
  option?: { [key: string]: string }
  upperLimitText?: string
}
export const formatAmountText = (meta: IFormatAmountText | undefined, from: number, to: number | null) => {
  if (meta) {
    return `${Intl.NumberFormat(meta.locale, meta.option).format(from)} - ${
      to !== null ? Intl.NumberFormat(meta.locale, meta.option).format(to) : meta.upperLimitText
    }`
  }
  return `${Intl.NumberFormat().format(from)} - ${to !== null && Intl.NumberFormat().format(to)}`
}

// eslint-disable-next-line @typescript-eslint/member-delimiter-style
export const filterTypeAndNameMatch = new Map<string, { key: string; iconName: IconNames }>([
  ['invoiceDate', { key: 'advancedFilters_filterName_invoiceDate', iconName: 'Date' }],
  ['invoiceType', { key: 'advancedFilters_filterName_invoiceType', iconName: 'Select' }],
  ['invoiceNumber', { key: 'advancedFilters_filterName_invoiceNumber', iconName: 'SpreadsheetGeneric' }],
  ['supplyCountry', { key: 'advancedFilters_filterName_supplyCountry', iconName: 'Flag' }],
  ['amount', { key: 'advancedFilters_filterName_amount', iconName: 'CvcCvv' }]
])

export const getFilterText = (
  { name, keyGroup, meta }: Filter,
  content: {
    [key: string]: string
  }
) => {
  const value = keyGroup?.[0]?.values?.[0] ?? ''
  let text = content[filterTypeAndNameMatch.get(name)?.key ?? 0]

  switch (name) {
    case 'invoiceDate':
      text += `: ${value?.from} - ${value.to ? value.to : ''}`
      break
    case 'amount':
      text += `: ${formatAmountText(meta, Number(value.from), value?.to ? Number(value?.to) : null)}`
      break
    case 'invoiceType':
      text += `: ${content[`advancedFilters_${value.invoiceType}`]}`
      break

    case 'invoiceNumber':
      text += `: ${value?.invoiceNumber}`
      break
    // supply country currently not used
    case 'supplyCountry': {
      text += `: ${content[`advancedFilters_${value.countryCode}`]}`
      break
    }

    default:
      break
  }

  return text
}
