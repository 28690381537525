import { createGlobalStyle } from 'styled-components'
import UniversforBPLightWoff from 'src/assets/fonts/UniversforBPLight.woff'
import UniversforBPLightWoff2 from 'src/assets/fonts/UniversforBPLight.woff2'
import UniversforBPRomanWoff from 'src/assets/fonts/UniversforBPRoman.woff'
import UniversforBPRomanWoff2 from 'src/assets/fonts/UniversforBPRoman.woff2'
import animations from './animations'
// import UniversforBPBoldWoff from 'src/assets/fonts/UniversforBPRoman.woff'
// import UniversforBPBoldWoff2 from 'src/assets/fonts/UniversforBPRoman.woff2'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, p, a, button {
    font-family: var(--typography-font-default), 'Univers', Arial, sans-serif;
  }

  p {
    font-size: 14px;
  }

  #root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }
  
  ${animations}

  @font-face {
    font-family: 'Univers';
    src: url(${UniversforBPLightWoff2}) format('woff2'),
      url(${UniversforBPLightWoff}) format('woff');
    font-weight: 200;
  }
  
  @font-face {
    font-family: 'Univers';
    src: url(${UniversforBPRomanWoff2}) format('woff2'),
      url(${UniversforBPRomanWoff}) format('woff');
  }
  
  @font-face {
    font-family: 'Univers-light';
    src: url(${UniversforBPLightWoff2}) format('woff2'),
      url(${UniversforBPLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'UniversforBP-light';
    src: url(${UniversforBPLightWoff2}) format('woff2'),
      url(${UniversforBPLightWoff}) format('woff');
  }
`

export default GlobalStyle
